import { default as ChartHome } from 'components/charts/ChartHome';
// import { isCheckOAuth, isAuthenticated } from 'lib/slices/connect';
import Landing from 'components/Landing/Landing';
import { checkOauthToken, tokenManager } from 'coral-utility';
import { useAppDispatch, useAppSelector } from 'lib/hooks/hooks';
import { useContext, useEffect } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

type Props = {
  children?: React.ReactNode;
};

/**
 * Security
 * Checks and dispatches for our current users and authentication status.
 */
function Security({ children }: Props) {
  const { token, tokenData } = useContext<IAuthContext>(AuthContext);

  // const authenticated = useAppSelector(isAuthenticated);
  // const checkOAuth = useAppSelector(isCheckOAuth);

  /**
   * (1) User has Token?
   *    1. Verify Token
   *        1. isValid?
   *           - Load into the app
   *        2. isNotValid?
   *           - Direct to landing page for sign-in.
   * (2) User does not have Token?
   *    1. - Direct to landing page for sign-in.
   */
  // useEffect(() => {
  //   if (tokenManager.hasToken()) {
  //     // call user verify action
  //     dispatch(checkOauthToken());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (checkOAuth) {
  //     dispatch(getCurrentUser());
  //   }
  // }, [checkOAuth, dispatch]);

  /**
   * (1) Checks for Authentication
   * (2)(Loads Landing Page)
   * (3)(Only renders app when we are fully authenticated)
   */
  /* eslint-disable */
  if (tokenData) {
    return <>{children}</>;
  }

  return <Landing />;
}

export default Security;
