import { useState, useEffect, useContext } from 'react';
import { StyledImage } from './styles';
import { IPicture } from 'types';
import { withToken, genFetch } from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

interface IProps {
  lessonId: string;
  picture: IPicture;
}

export default function ApiImage({ lessonId, picture }: IProps) {
  // Local State
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { token } = useContext<IAuthContext>(AuthContext);


  /**
   * 1: Do request if url supplied
   * 2: Create an Object URL
   * 3: Return an <Image />
   */
  useEffect(() => {
    // Like Component did mount
    if (lessonId && picture) {
      // Call API request for image
      genFetch(lessons.downloadFile({ id: lessonId }))(
        withToken(token)
      )().then((initResponse: any) => {
        if (initResponse.status === 200) {
          initResponse.blob().then((blob: any) => {
            const url = window.URL.createObjectURL(blob);
            setImageURL(url);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });
    } else if (lessonId && !picture) {
      //we have a lesson but no picture - then we load default
      setLoading(false);
    }
  }, [lessonId, picture]);

  return (
    <>
      <StyledImage
        src={imageURL ? imageURL : ''}
        title={picture ? picture.caption : ''}
        load={loading}
        alt={picture ? picture.originalFileName : ''}
        style={{ marginTop: '1rem' }}
      />
    </>
  );
}
