import { Col, Layout, Row } from 'antd';
import useGetSearch from 'components/Browse/hooks/useGetSearch';
import useGetTags from 'components/Browse/hooks/useGetTags';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { ChartContainer } from './ChartContainer';
import { InfoDescriptions } from './constants';
import GroupedBarChart from './GroupedBarChart';
import useAnalytics from './hooks/useAnalytics';
import PieChart from './PieChart';
import { parseSearch } from './utils/parseSearch';

const { Content } = Layout;

const StyledContent = styled(Content)`
  padding: 0 5rem;
  background-color: white;
`;

const RowContainer = styled(Row)`
  margin-bottom: 1rem;
  width: 100%;
`;

const { Header } = Layout;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  background-color: white;
`;

const ChartHeader: React.FC = () => (
  <StyledHeader>
    <span style={HeaderText}>Observation Analytics</span>
  </StyledHeader>
);

const ChartContent: React.FC = () => {
  const { getSearchResults, searchResults, searchLoading } = useGetSearch();
  const { getTags, allTags, allTagsLoading } = useGetTags();
  const { getAnalytics, tfidf, analyticsLoading } = useAnalytics();

  const lessonTypeRef = useRef<SVGSVGElement | null>(null);
  const lessonEventRef = useRef<SVGSVGElement | null>(null);
  const overallCategoryRef = useRef<SVGSVGElement | null>(null);
  const eventCategoryRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    getTags();
    getSearchResults(undefined, {});
    getAnalytics();
  }, []);

  const eventNames = useMemo(() => {
    const events = allTags.find((tag) => tag.name === 'Event')?.listItems;
    if (!events) return {};
    return Object.fromEntries(events?.map((value) => [value.tag.id, value.tag.name]));
  }, [allTags]);

  const typeNames = useMemo(() => {
    const types = allTags.find((tag) => tag.name === 'Observation Type')?.listItems;
    if (!types) return {};
    return Object.fromEntries(types.map((value) => [value.tag.id, value.tag.name]));
  }, [allTags]);

  const lessonEventData = useMemo(() => {
    return parseSearch(searchResults, 'events', eventNames);
  }, [searchResults, eventNames]);

  const lessonTypeData = useMemo(() => {
    return parseSearch(searchResults, 'observation_types', typeNames);
  }, [searchResults, typeNames]);

  const overallCategoryData = useMemo(() => {
    if (!tfidf) return [];
    else {
      const data = Object.entries(tfidf.overall_category_count.description).map(([label, value]) => ({ label, value }));
      return data.filter((slice) => slice.value > 0);
    }
  }, [tfidf]);

  const eventCategoryData = useMemo(() => {
    if (!tfidf) return [];
    else {
      const data = Object.entries(tfidf.event_category_count);
      const arrays = data.map(([eventId, model]) => {
        const eventName = eventNames[eventId] ?? 'Unknown';
        const eventData = Object.entries(model.description).map(([label, value]) => ({
          label,
          value,
          group: eventName,
        }));
        return eventData;
      });
      return arrays.flat().filter((data) => data.value !== 0);
    }
  }, [tfidf, eventNames]);

  const isLoading = searchLoading || allTagsLoading || analyticsLoading;
  const info = InfoDescriptions;

  return (
    <StyledContent>
      <RowContainer>
        <Col span={12}>
          <ChartContainer
            title="Observation per Type"
            description={info.observationPerType}
            svg={lessonTypeRef.current}
            id="perType"
            isLoading={isLoading}
          >
            <PieChart svgRef={lessonTypeRef} data={lessonTypeData} id="perType" />
          </ChartContainer>
        </Col>
        <Col span={12}>
          <ChartContainer
            title="Observation per Event"
            description={info.observationPerEvent}
            svg={lessonEventRef.current}
            isLoading={isLoading}
            id="perEvent"
          >
            <PieChart svgRef={lessonEventRef} data={lessonEventData} id="perEvent" />
          </ChartContainer>
        </Col>
      </RowContainer>
      <RowContainer>
        <Col span={12}>
          <ChartContainer
            title="Observation per Category"
            description={info.observationPerCat}
            svg={overallCategoryRef.current}
            isLoading={isLoading}
            id="perCat"
          >
            <PieChart svgRef={overallCategoryRef} data={overallCategoryData} id="perCat" />
          </ChartContainer>
        </Col>
        <Col span={12}>
          <ChartContainer
            title="Observation per Category, by Event"
            description={info.observationPerCatByEvent}
            svg={eventCategoryRef.current}
            isLoading={isLoading}
            id="perCatEvent"
          >
            <GroupedBarChart data={eventCategoryData} svgRef={eventCategoryRef} id="perCatEvent" />
          </ChartContainer>
        </Col>
      </RowContainer>
    </StyledContent>
  );
};

const ChartHome: React.FC = () => (
  <Layout>
    <ChartHeader />
    <ChartContent />
  </Layout>
);

const HeaderText: React.CSSProperties = {
  fontSize: '1.5rem',
  color: 'rgb(70, 71, 73)',
};

export default ChartHome;
