import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import App from 'pages/_app';
import BaseRouter from 'pages/_base-router';
import Providers from 'pages/_providers';
import Security from 'pages/_security';
import ReactDOM from 'react-dom/client';
import { AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';

require('typeface-archivo');
require('typeface-roboto');

export const authConfig: TAuthConfig = {
  clientId: `${process.env.REACT_APP_CLIENT_ID}`,
  authorizationEndpoint: `${process.env.REACT_APP_API_AUTH_URI}`,
  tokenEndpoint: `${process.env.REACT_APP_API_TOKEN_URI}`,
  redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
  scope: `${process.env.REACT_APP_SCOPE}`,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => event.logIn(undefined, undefined, 'popup'),
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Providers>
    <AuthProvider authConfig={authConfig}>
      <Security>
        <ErrorBoundary>
          <BaseRouter>
            <App />
          </BaseRouter>
        </ErrorBoundary>
      </Security>
    </AuthProvider>
  </Providers>
);
