import { notification } from 'antd';
import {
    genFetch,
    withToken,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';


export default function useDownloadFile() {
    const { token } = useContext<IAuthContext>(AuthContext);

    const downloadFile = async (id: string, filename: string) => {
        try {
            genFetch(lessons.downloadFile({id: id}))(withToken(token))().then(response => {
                if (response.status === 200) {
                    response.blob().then(blob => {     
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = "_blank";
                        link.setAttribute('download', filename);
                        link.click();       
                    //     const a = document.createElement("a");
                    //     a.target = "_blank";
                    //     document.body.appendChild(a);
                    //     a.style.display = "none";
                    //     const url = window.URL.createObjectURL(blob);
                    //     a.href = url;
                    //     if (filename) {
                    //         a.download = filename;
                    //     } //otherwise browser will handle
                    //     a.click();
                    //     window.URL.revokeObjectURL(url);
                    //     document.body.removeChild(a);
                    });
                } else {
                notification.error({
                    description: "Download File failed.",
                    message: "Error Downloading File",
                  });
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    return { downloadFile };
}
