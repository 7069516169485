import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import navigationReducer from 'lib/slices/navigation';
import { OAuthReducer, sagaRegistry, OAuthSaga } from 'coral-utility';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    // connect: connectReducer,
    oauth: OAuthReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([sagaMiddleware]),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaRegistry.setChangeListener((sagas: any) => {
  sagaMiddleware.run(sagas);
});

sagaRegistry.register('oauth', OAuthSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
