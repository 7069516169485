import { useState, useContext } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { Cart } from 'types/cartTypes';

export default function useAddCart() {
    const [addCartLoading, setAddCartLoading] = useState(false);
    const [added, setAdded] = useState(false);
    const [newCart, setNewCart] = useState({
        id: '',
        user: '',
        name: '',
    });
    const { token } = useContext<IAuthContext>(AuthContext);


    const addCart = async (cartName: any, setAdd: any, callback?: any) => {
        setAddCartLoading(true);
        setAdd(false);
        try {
            const request = {
                onFetch: () => genFetch(lessons.addCart(cartName))(withToken(token))(RelationshipsEnums.NONE),
                onSuccess: async (coreJsonResponse: any) => {
                    const carts: Cart[] = coreJsonResponse.data;
                    const cart = carts.at(-1);

                    if(cart) {
                        notification.success({
                            description: `${cart.name} was created.`,
                            duration: 3,
                            message: 'Success',
                        });

                        setNewCart(coreJsonResponse.data);


                        if (callback) {
                            callback(cart.id);
                        }
                    }


                    setAddCartLoading(false);
                    setAdded(true);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Add cart failed.',
                        message: 'Failure Adding Cart',
                    });
                    setAddCartLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    return { addCart, newCart, addCartLoading, added, setAdded };
}