import { notification } from 'antd';
import { genFetch, RelationshipsEnums, requestHandler, RequestType, withToken } from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useCreateNewLesson() {
  const [creationDone, setCreationDone] = useState(false);
  const { token } = useContext<IAuthContext>(AuthContext);

  const createNewLesson = async (newLessonObj: any) => {
    setCreationDone(false);
    try {
      const request = {
        onFetch: () => genFetch(lessons.addLesson(newLessonObj))(withToken(token))(RelationshipsEnums.NONE),
        onSuccess: () => {
          notification.success({
            description: 'Successfully Created',
            duration: 0,
            message: 'New Lesson Created.',
          });
          setCreationDone(true);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Create New Lesson Failed',
            message: 'Error Creating New Lesson Event',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };

      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { createNewLesson, creationDone };
}
