import { Menu } from '@headlessui/react';
import { createSelector } from '@reduxjs/toolkit';
import { Token, tokenManager } from 'coral-utility';
import jwtDecode from 'jwt-decode';
import { useAppDispatch, useAppSelector } from 'lib/hooks/hooks';
import { useConnected } from 'lib/slices/connect';
import { getHeaderState as getHeaderBaseState } from 'lib/slices/navigation';
import { useContext, useEffect, useMemo } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import { ReactComponent as Logo } from './logo.svg';
import {
  Header,
  LogoutLink,
  MenuButton,
  MenuIcon,
  MenuItem,
  MenuItems,
  MenuItemsTabs,
  MenuLink,
  ProfileButton,
  ProfileIcon,
  StyledMenu,
  StyledTabs,
  SubTitle,
  Title,
} from './styles';

function capitalize(str1: string) {
  return str1.charAt(0).toUpperCase() + str1.slice(1);
}

// const getHeaderState = () =>
//   createSelector(getHeaderBaseState, (state) => {
//     const { navigation } = state;
//     const token: Token | undefined = tokenManager.hasToken()
//       ? (jwtDecode(tokenManager.getToken()) as Token)
//       : undefined;

//     if (token) {
//       const firstInitial = token.first.length > 0 ? token.first.charAt(0) : null;
//       const secondInitial = token.last.length > 0 ? token.last.charAt(0) : null;

//       if (firstInitial && secondInitial) token.initials = `${firstInitial}+${secondInitial}`;
//       token.first = capitalize(token.first);
//       token.last = capitalize(token.last);
//     }

//     return {
//       user: token,
//       navigation,
//       // theme,
//     };
//   });

function Navigation() {
  // const { navigation } = useAppSelector(getHeaderState());
  const { logOut } = useContext<IAuthContext>(AuthContext);
  const { user, getUser } = useConnected();

  useEffect(() => {
    getUser();
  }, []);

  const logout = () => {
    logOut();
  };

  const username = user?.name ?? 'Not Found';
  const initials = user?.name?.charAt(0) ?? 'NU';
  const BASE_URL = process.env.REACT_APP_BASE_URL || '';

  // Profile Picture Configuration
  const color = 'FFF';
  const background = '007836';

  const header = () => {
    return (
      <Header style={{ height: 'auto' }}>
        <Title>
          GANDALF <SubTitle>(Growth and New Development through Assessment of Lessons from the Field)</SubTitle>
        </Title>
        <Menu key="profile" as={StyledMenu}>
          <MenuButton>
            <ProfileButton>
              <ProfileIcon
                src={`https://ui-avatars.com/api/?rounded=true&name=${
                  initials !== 'H+O' ? initials : username[0]
                }&size=30&font-size=.5&bold=true&background=${background}&color=${color}&format=svg`}
              />
              {user?.name}
              <MenuIcon />
            </ProfileButton>
          </MenuButton>
          <MenuItems>
            <LogoutLink title="Logout" onClick={logout}>
              Logout
            </LogoutLink>
          </MenuItems>
        </Menu>
      </Header>
    );
  };

  const tabs = () => {
    return (
      <StyledTabs>
        <MenuLink title={'Browse'} to={`${BASE_URL}browse`} key={'browse'}>
          Browse
        </MenuLink>
        <MenuLink title={'Analytics'} to={`${BASE_URL}analytics`} key={'analytics'}>
          Analytics
        </MenuLink>
        <MenuLink title={'Favorites'} to={`${BASE_URL}cart`} key={'favorites'}>
          Favorites
        </MenuLink>

        {/* {navigation.items.map((route: any) => {
          if (route.__children.length > 0) {
            return (
              <Menu
                key={route.label}
                as={StyledMenu}
              >
                <MenuButton className='tab-item'>{route.label}</MenuButton>
                <MenuItemsTabs>
                  <div>
                    {route.__children.map((child: any) => {
                      return (
                        <MenuItem
                          to={`${BASE_URL}${child.uri}`}
                          title={child.label}
                          key={child.id}
                        >
                          {child.label}
                        </MenuItem>
                      )
                    })}
                  </div>
                </MenuItemsTabs>
              </Menu>
            )
          }
          return (
            <MenuLink
              title={route.tooltip}
              to={`${BASE_URL}${route.uri}`}
              key={route.label}
            >
              {route.label}
            </MenuLink>
          );
        })} */}
      </StyledTabs>
    );
  };

  return (
    <>
      {header()}
      {tabs()}
    </>
  );
}

export default Navigation;
