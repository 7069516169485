import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { LargeSpinner } from 'components/lesson/lesson-sc';
import React from 'react';

import { exportSvgToPng } from './utils/exportToPng';

export type ChartProps = {
  title: string;
  description?: React.ReactNode;
  noDownload?: boolean;
  children: React.ReactNode;
  svg?: SVGSVGElement | null;
  isLoading?: boolean;
  id: string;
};

export const ChartContainer = ({ title, description, noDownload, children, svg, id, isLoading }: ChartProps) => {
  const filename = `${title}.png`;

  return (
    <div style={ContainerStyle}>
      <div style={HeaderStyle}>
        <h4>{title}</h4>
        <div style={ButtonStyle}>
          <Popover title="Info" content={description} overlayStyle={{ width: '20rem', margin: '5rem' }}>
            <InfoCircleOutlined />
          </Popover>
          {!noDownload && (
            <Tooltip title={`Download Chart`}>
              <Button
                type="text"
                icon={<DownloadOutlined />}
                disabled={!svg}
                onClick={svg ? () => exportSvgToPng(svg, filename) : undefined}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div style={{ textAlign: 'center', visibility: isLoading ? 'visible' : 'hidden' }}>{<LargeSpinner />}</div>
      <div style={{ textAlign: 'center', visibility: isLoading ? 'hidden' : 'visible' }} id={id}>
        {children}
      </div>
    </div>
  );
};

const ContainerStyle: React.CSSProperties = {
  margin: '0.5rem',
  border: '1px solid lightgrey',
  borderRadius: '5px',
  boxShadow: '#d9d9d9 1px 1px 6px 1px',
};

const HeaderStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 1rem',
  alignItems: 'center',
};

const ButtonStyle: React.CSSProperties = {
  display: 'flex',
  gap: '0.5rem',
};
