import { notification } from 'antd';
import { genFetch, RelationshipsEnums, requestHandler, RequestType, withToken } from 'coral-utility';
import { search } from 'lib/api/search';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useGetFilters() {
  const [allFilters, setFilters] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(false);
  const { token } = useContext<IAuthContext>(AuthContext);

  const getFilters = async (filters: any) => {
    setFiltersLoading(true);
    try {
      const request = {
        onFetch: () => genFetch(search.getFilters(filters))(withToken(token))(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setFilters(coreJsonResponse.data);
          setFiltersLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get all filters failed.',
            message: 'Error Getting Filters',
          });
          setFiltersLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { allFilters, filtersLoading, getFilters };
}
