import { Table } from 'antd';
import useGetCart from 'components/cart/hooks/useGetCart';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Lesson } from 'types';
import * as GEN_TYPES from 'types/cartTypes';

import CartTableFooter from './CartTableFooter';

interface Props {
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
}

const CartTable: React.FC<Props> = (props) => {
  const { selectedCart, setSelectedCart } = props;
  const [tableData, setTableData] = useState<Lesson[]>([]);
  const [selectedFiles, setSelectedLessons] = useState<Lesson[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const { getCart, cart, getCartLoading } = useGetCart();

  useEffect(() => {
    setSelectedLessons([]);
    setSelectedRowKeys([]);
  }, [selectedCart]);

  useEffect(() => {
    async function getCartFromId() {
      if (selectedCart !== undefined) {
        getCart(selectedCart.id);
      }
    }
    getCartFromId();
  }, [selectedCart]);

  useEffect(() => {
    if (!selectedCart) {
      setTableData([]);
      return;
    }
    if (cart !== undefined) {
      const fileKeys = cart.lessons ? Object.keys(cart.lessons) : [];
      const fileItems = fileKeys.map((key: string) => {
        return {
          ...cart.lessons[key],
          key: cart.lessons[key].id,
        };
      });
      setTableData(fileItems);
    }
  }, [selectedCart, cart]);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Last Updated Time Stamp',
      dataIndex: 'lastUpdatedStamp',
      key: 'lastUpdatedStamp',
    },
    {
      title: 'To Lesson',
      dataIndex: 'id',
      key: 'id',
      render: (lessonId: string) => {
        return (
          <Link to={`/lesson?id=${lessonId}`} target="_blank">
            Link to the Lesson
          </Link>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (_selectedRowKeys: React.Key[], selectedRows: Lesson[]) => {
      setSelectedRowKeys(selectedRows.map((row: Lesson) => row.id));
      setSelectedLessons(selectedRows);
    },
    getCheckboxProps: (record: Lesson) => ({
      name: record.title,
    }),
  };

  return (
    <CartTableWrapper>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        loading={getCartLoading}
        dataSource={tableData}
        columns={columns}
        scroll={{ y: 550 }}
        footer={() => {
          return (
            <CartTableFooter
              selectedCart={selectedCart}
              setSelectedCart={setSelectedCart}
              selectedFiles={selectedFiles}
            />
          );
        }}
      />
    </CartTableWrapper>
  );
};

const CartTableWrapper = styled.div`
  width: 80%;

  & .ant-pagination {
    position: absolute;
    right: 1rem;
    bottom: 0;
  }
`;

export default CartTable;
