import type { InputRef } from 'antd';
import { default as Filter } from 'components/common/Filter/Filter';
import React, { useRef, useState } from 'react';
import { TagList } from 'types';

import { AddLessonButton, FeaturesContainer, FeaturesHeader, FeaturesHeaderText, Search } from './search-sc';

interface Props {
  initSearchText: string;
  updateURL: any;
  filtersLoading: boolean;
  buckets: any;
  filters: any;
  clearSearch: any;
  tags: TagList[];
}

const SearchBox = (props: Props) => {
  const { initSearchText, buckets, filtersLoading, filters, tags } = props;
  const [searchText, setSearchText] = useState(initSearchText);

  function handleChange(e: any) {
    setSearchText(e.target.value);
  }

  function applyFilters(filters: any) {
    props.updateURL(filters, searchText);
  }

  function clearFilters() {
    setSearchText('');
    props.clearSearch();
    props.updateURL({}, '');
  }

  return (
    <FeaturesContainer>
      <FeaturesHeader>
        <FeaturesHeaderText>Observation Features</FeaturesHeaderText>
      </FeaturesHeader>
      <AddLessonButton to="/add">Add an Observation</AddLessonButton>
      <FeaturesHeader>
        <FeaturesHeaderText>Search</FeaturesHeaderText>
      </FeaturesHeader>
      <Search
        placeholder="Search by title or keyword"
        value={searchText}
        onChange={handleChange}
        onSubmit={() => applyFilters(filters)}
      />
      <Filter
        buckets={filtersLoading === true ? {} : buckets}
        applyFilters={applyFilters}
        clearFilters={clearFilters}
        selectedFilters={filters}
        tags={tags}
      />
    </FeaturesContainer>
  );
};

export default SearchBox;
