import { useContext, useState } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useDeleteLesson() {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteFailed, setDeleteFailed] = useState(false);
    const { token } = useContext<IAuthContext>(AuthContext);


    const deleteLesson = async (id: string) => {
        setDeleteLoading(true);
        try {
            const request = {
                onFetch: () => genFetch(lessons.deleteLesson(id))(withToken(token))(RelationshipsEnums.NONE),
                onSuccess: (coreJsonResponse: any) => {
                    notification.success({
                        duration: 0,
                        message: 'Lesson Deleted',
                    });
                    setDeleteLoading(false);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Get tags failed.',
                        message: 'Error Getting Tags',
                    });
                    setDeleteFailed(true);
                    setDeleteLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    return { deleteLesson, deleteLoading, deleteFailed };
}
