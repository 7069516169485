import { notification } from 'antd';
import { CoreJsonResponse, genFetch, RelationshipsEnums, requestHandler, RequestType, withToken } from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { TagList } from 'types';

export default function useGetAllTags() {
  const [allTags, setTags] = useState<TagList[]>([]);
  const [getTagsLoading, setGetTagsLoading] = useState(false);
  const { token } = useContext<IAuthContext>(AuthContext);

  const getAllTags = async () => {
    setGetTagsLoading(true);
    try {
      const request = {
        onFetch: () => genFetch(lessons.getTags())(withToken(token))(RelationshipsEnums.INCLUDE_RELATIONS),
        onSuccess: (coreJsonResponse: CoreJsonResponse<TagList[], string>) => {
          setTags(coreJsonResponse.data);
          setGetTagsLoading(false);
        },
        onFailure: (messageText: string) => {
          setGetTagsLoading(false);
          notification.error({
            description: messageText ?? 'Get All Tags Failed',
            message: 'Error Getting All Tags',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { getTagsLoading, allTags, getAllTags };
}
