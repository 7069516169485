import { useContext, useState } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { TagList } from 'types';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useGetTags() {
    const [allTags, setAllTags] = useState<TagList[]>([]);
    const [allTagsLoading, setAllTagsLoading] = useState(false);
    const [getAllTagsFailed, setGetAllTagsFailed] = useState(false);
    const { token } = useContext<IAuthContext>(AuthContext);


    const getTags = async () => {
        //if already loaded - don't query again
        if (allTags.length) return;
        setAllTagsLoading(true);
        try {
            const request = {
                onFetch: () => genFetch(lessons.getTags())(withToken(token))(),
                onSuccess: (coreJsonResponse: any) => {
                    setAllTags(coreJsonResponse.data);
                    setAllTagsLoading(false);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Get tags failed.',
                        duration: 0,
                        message: 'Error Getting Tags',
                    });
                    setGetAllTagsFailed(true);
                    setAllTagsLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    return { getTags, allTags, setAllTags, allTagsLoading, getAllTagsFailed };
}
