import { useCallback, useContext } from 'react';
import { openAuthenticationWindowAction } from 'coral-utility';
import { animated, useSpring } from 'react-spring';
import LandingIcon from 'components/Landing/landingIcon';

import {
  StyledBackground,
  StyledHeader,
  StyledRow,
  StyledTitle,
  StyledLogoContainer,
  StyledLoginContainer,
  StyledTextWrapper,
  StyledHeaderDisclaimer,
  StyledHR,
  StyledLink,
} from './styles';
import { useAppDispatch } from 'lib/hooks/hooks';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

function Landing() {
  const {logIn} = useContext<IAuthContext>(AuthContext)
  const openAuthWindow = useCallback(() => {
    console.log('here')
    logIn();

  //   dispatch(
  //     openAuthenticationWindowAction(
  //       `${process.env.REACT_APP_API_URI}/auth/login?client_id=${process.env.REACT_APP_CLIENT_ID}`,
  //     ),
  //   );
  }, [logIn]);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 100,
  });

  return (
    <StyledBackground>
      <animated.div style={fadeIn} className="dmamc-animated-container">
        <StyledRow style={{ width: '100%', maxWidth: '1600px' }}>
          <StyledLogoContainer>
            <LandingIcon />
          </StyledLogoContainer>
          <StyledLoginContainer>
            <StyledTextWrapper>
              <StyledHeader>
                <StyledTitle>GANDALF</StyledTitle>
              </StyledHeader>
              <StyledHeaderDisclaimer>
                This is a Federal computer system and is the property of the United States
                Government. It is for authorized official use only. Users have no explicit or
                implicit expectation of privacy.
              </StyledHeaderDisclaimer>
              <StyledHR />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <StyledLink onClick={openAuthWindow}>Login with SHIRE</StyledLink>
              </div>
            </StyledTextWrapper>
          </StyledLoginContainer>
        </StyledRow>
      </animated.div>
    </StyledBackground>
  );
}

export default Landing;
