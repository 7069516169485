import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Table, Tag, Input, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { PrivilegeButton } from './PrivilegeButton';
import { roleToColor } from '../../util/roleToColor';
import { genFetch, withToken } from '../../../../../utils';
import { privileges } from '../../../state/api/privileges';
import { roles } from '../../../state/api/roles';
import { resources } from '../../../state/api/resources';
import { PrivilegeBulkAddButton } from './PrivilegeBulkAddButton';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

interface Props {
  adminServiceURL: string;
  columnDefinitions?: [];
}

const operations: any = [
  {
    id: false,
    name: 'ADD',
    value: false,
  },
  {
    name: 'REMOVE',
    id: true,
    value: true,
  },
];

const privilegeColumnDefinitions = [
  {
    title: 'ROLE',
    key: 'roleName',
    dataIndex: 'roleName',
    render: (role: any, record: any) => (
      <Tag key={record.id} color={roleToColor(role)}>
        {role}
      </Tag>
    ),
    sorter: (a: any, b: any) => (a.roleName > b.roleName ? 1 : b.roleName > a.roleName ? -1 : 0),
  },
  {
    title: 'RESOURCE TYPE',
    key: 'resourceType',
    dataIndex: 'resourceType',
    sorter: (a: any, b: any) =>
      a.resourceType > b.resourceType ? 1 : b.resourceType > a.resourceType ? -1 : 0,
  },
  {
    title: 'RESOURCE NAME',
    key: 'resourceName',
    dataIndex: 'resourceName',
    sorter: (a: any, b: any) =>
      a.resourceName > b.resourceName ? 1 : b.resourceName > a.resourceName ? -1 : 0,
  },
  {
    title: 'RESOURCE CLASS',
    key: 'resourceClass',
    dataIndex: 'resourceClass',
    sorter: (a: any, b: any) =>
      a.resourceClass > b.resourceClass ? 1 : b.resourceClass > a.resourceClass ? -1 : 0,
  },
  {
    title: 'INSTANCE',
    key: 'instance',
    dataIndex: 'instance',
  },
  {
    title: 'PRIVILEGES',
    key: 'privilege',
    dataIndex: 'privilege',
    render: (record: any) =>
      record.map((item: string) => <Tag key={item}>{item.toUpperCase()}</Tag>),
  },
];

export const PrivilegesTable: React.FC<Props> = props => {
  const { adminServiceURL } = props;
  const [privilegesData, setPrivilegesData] = useState<any>([]);
  const [permissionsData, setPermissionsData] = useState<any>([]);
  const [permissionsMap, setPermissionsMap] = useState<any>({});
  const [rolesData, setRolesData] = useState<any>([]);
  const [resourcesData, setResourcesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);
  const { token } = useContext<IAuthContext>(AuthContext);

  const getPermission = useCallback(() => {
    genFetch(privileges.getPermissionsMap(adminServiceURL))(withToken(token))().then(
      (initialResponse: any) => {
        if (initialResponse.status === 200) {
          initialResponse.json().then((body: any) => {
            const map = Object.keys(body.data).map((key: string) => ({
              label: key,
              value: key,
              id: key,
            }));
            setPermissionsMap(body.data);
            setPermissionsData(map);
          });
        }
      }
    );
  }, [adminServiceURL]);

  const getRoles = useCallback(() => {
    genFetch(roles.getRoles(adminServiceURL))(withToken(token))().then((initResponse: any) => {
      if (initResponse.status === 200) {
        initResponse.json().then((body: any) => {
          setRolesData(body.data);
        });
      }
    });
  }, [adminServiceURL]);

  const getResources = useCallback(() => {
    genFetch(resources.getResources(adminServiceURL))(withToken(token))().then(
      (initResponse: any) => {
        if (initResponse.status === 200) {
          initResponse.json().then((body: any) => {
            setResourcesData(body.data);
          });
        }
      }
    );
  }, [adminServiceURL]);

  const getPrivileges = useCallback(() => {
    setLoading(true);
    genFetch(privileges.getPrivileges(adminServiceURL))(withToken(token))(
      '?include_privileges=true'
    ).then((initialResponse: any) => {
      if (initialResponse.status === 200) {
        initialResponse.json().then((body: any) => {
          setPrivilegesData(body.data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [adminServiceURL]);

  useEffect(() => {
    getPrivileges();
    getPermission();
    getResources();
    getRoles();

  }, [getPrivileges, getPermission, getResources, getRoles]);


  const handleSearch = useCallback(() => {
    const searchArray = privilegesData.filter((item: any) =>
      privilegeColumnDefinitions.some((definition: any) => {
        return typeof item[definition.dataIndex] === 'string'
          ? item[definition.dataIndex].toLowerCase().includes(searchText.toLowerCase())
          : false;
      })
    );
    setSearchResults(searchArray);
  }, [privilegesData, searchText]);

  useEffect(() => {
    if (searchText.length > 0) {
      handleSearch();
    }
  }, [handleSearch, searchText.length]);

  const actionDefinitions = [
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (record: any) => (
        <>
          <span style={{ padding: '0 1rem' }} key={record.id}>
            <PrivilegeButton
              edit
              privilege={JSON.parse(JSON.stringify(record))}
              privilegeGroup={permissionsData}
              permissionMap={permissionsMap}
              roles={rolesData}
              resources={resourcesData}
              onSubmit={handlePrivilegeUpdate}
            />
          </span>
          <Popconfirm
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Are you sure you want to delete this privilege?</span>
              </div>
            }
            onConfirm={() => handlePrivilegeDeletion(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];



  const handlePrivilegeUpdate = (privilegeId: string, privilege: object) => {
    genFetch(privileges.editPrivilege(adminServiceURL, privilegeId, privilege))(
      withToken(token)
    )().then(getPrivileges);
  };

  const handlePrivilegeCreation = (privilege: object) => {
    genFetch(privileges.newPrivilege(adminServiceURL, privilege))(withToken(token))().then(
      getPrivileges
    );
  };

  const handlePrivilegeDeletion = (privilegeId: string) => {
    genFetch(privileges.deletePrivilege(adminServiceURL, privilegeId))(withToken(token))().then(
      getPrivileges
    );
  };

  const handleBulkOperation = (bulkOperation: object) => {
    genFetch(privileges.bulkPrivilegeOperation(adminServiceURL, bulkOperation))(
      withToken(token)
    )().then(getPrivileges);
  };

  return (
    <div style={{ width: '100%', padding: '2rem', display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1600px', width: '100%' }}>
        <Table
          dataSource={searchText.length > 0 ? searchResults : privilegesData}
          columns={[...privilegeColumnDefinitions, ...actionDefinitions]}
          loading={loading}
          rowKey={privilegesData => 'privileges-' + privilegesData.id}
        />
        <div style={{ padding: '0 1em 0 0', display: 'inline-block' }}>
          <PrivilegeButton
            privilegeGroup={permissionsData}
            permissionMap={permissionsMap}
            roles={rolesData}
            resources={resourcesData}
            onSubmit={handlePrivilegeCreation}
          />
        </div>
        <div style={{ padding: '0 1em 0 0', display: 'inline-block' }}>
          <PrivilegeBulkAddButton
            privilegeGroup={permissionsData}
            permissionMap={permissionsMap}
            operations={operations}
            roles={rolesData}
            onSubmit={handleBulkOperation}
          />
        </div>
        <div style={{ padding: '0 1em 0 0', display: 'inline-block' }}>
          <Input.Search
            onSearch={() => handleSearch()}
            onChange={(e: any) => {
              setSearchText(e.target.value);
              handleSearch();
            }}
            value={searchText}
          />
        </div>
      </div>
    </div>
  );
};
