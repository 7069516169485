import * as d3 from 'd3';
import { useEffect } from 'react';

export interface BarChartProps {
  data: BarData[];
  width?: number;
  height?: number;
  color?: string;
  svgRef: React.MutableRefObject<SVGSVGElement | null>;
  id: string;
}

export interface BarData {
  label: string;
  value: number;
  group: string;
}

export const GroupedBarChart = ({
  svgRef,
  data,
  width = 500,
  height = 400,
  color = 'steelblue',
  id,
}: BarChartProps) => {
  useEffect(() => {
    // Clear previous SVG content
    const svg = d3.select(svgRef.current);
    const container = d3.select(`#${id}`);

    svg.selectAll('*').remove();
    container.selectAll('.tooltip').remove();

    svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white');

    // Set up margins and dimensions
    const margin = { top: 20, right: 30, bottom: 40, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create the SVG container
    const svgContainer = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // List of subgroups = header of the csv files = soil condition here
    const subgroups = new Set(data.map((d) => d.label));

    const fx = d3
      .scaleBand()
      .domain(new Set(data.map((d) => d.group)))
      .rangeRound([margin.left, innerWidth])
      .paddingInner(0.1);

    const color = d3.scaleOrdinal(d3.schemePastel2);

    const x = d3.scaleBand().domain(subgroups).rangeRound([0, fx.bandwidth()]).padding(0.05);

    // Y encodes the height of the bar.
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value) ?? 0])
      .nice()
      .rangeRound([innerHeight, 0]);

    const tooltip = container
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid lightgrey')
      .style('position', 'fixed')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('z-index', 5)
      .style('padding', '5px');

    const onMouseMove = (data: React.MouseEvent<HTMLElement>) => {
      tooltip.style('top', data.clientY + 10 + 'px').style('left', data.clientX + 10 + 'px');
    };

    const onMouseOver = (data: React.MouseEvent<HTMLElement>) => {
      const id = `#${data.currentTarget.id}`;
      const point = d3.select(id)?.datum() as BarData;

      tooltip.style('opacity', 1).html(`<b>Category</b> - ${point.label}<br><b>Value</b> - ${point.value}`);
    };

    const onMouseLeave = () => tooltip.style('opacity', 0).html('');

    // Append a group for each state, and a rect for each age.
    svgContainer
      .append('g')
      .selectAll()
      .data(d3.group(data, (d) => d.group))
      .join('g')
      .attr('transform', ([label]) => `translate(${fx(label)},0)`)
      .selectAll()
      .data(([, d]) => d)
      .join('rect')
      .attr('x', (d) => x(d.label) ?? '')
      .attr('y', (d) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d) => y(0) - y(d.value))
      .attr('fill', (d) => color(d.label))
      .attr('id', (d) => `${d.label}-${d.group}`.replaceAll(/[^a-z]+/gi, '-'))
      .on('mouseover', onMouseOver)
      .on('mousemove', onMouseMove)
      .on('mouseout', onMouseLeave);

    // Append the horizontal axis.
    svgContainer
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(fx).tickSizeOuter(0))
      .call((g) => g.selectAll('.domain').remove());

    const maxValue = Math.max(...data.map((value) => value.value));
    // Append the vertical axis.
    svgContainer
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(maxValue, 'd'))
      .call((g) => g.selectAll('.domain').remove());
  }, [data, width, height, color]);

  return <svg ref={svgRef}></svg>;
};

export default GroupedBarChart;
