import * as d3 from 'd3';
import React, { useEffect } from 'react';

// Define the types for your data
interface PieChartDataPoint {
  label: string;
  value: number;
}

export interface PieChartProps {
  svgRef: React.MutableRefObject<SVGSVGElement | null>;
  data: PieChartDataPoint[];
  height?: number;
  width?: number;
  id: string;
}

const PieChart: React.FC<PieChartProps> = ({ data, svgRef, height = 400, id }) => {
  useEffect(() => {
    if (!svgRef.current) return; // Ensure svgRef.current is not null

    const svg = d3.select(svgRef.current);
    const container = d3.select(`#${id}`);

    const width = parseInt(container.style('width'), 10);

    const radius = Math.min(width * 0.5, height) / 2;

    // Clear previous contents
    svg.selectAll('*').remove();
    container.selectAll('.tooltip').remove();

    svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white');

    // Create the SVG container
    const g = svg
      .attr('width', '100%')
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    // Create a pie chart layout
    const pie = d3.pie<PieChartDataPoint>().value((d) => d.value);

    // Create an arc generator
    const arc = d3
      .arc<d3.PieArcDatum<PieChartDataPoint>>()
      .innerRadius(0)
      .outerRadius(radius - 10);

    // Create color scale
    const color = d3.scaleOrdinal(d3.schemePastel2);

    const tooltip = container
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid lightgrey')
      .style('position', 'fixed')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('z-index', 5)
      .style('padding', '5px');

    const onMouseMove = (data: React.MouseEvent<HTMLElement>) => {
      tooltip.style('top', data.clientY + 10 + 'px').style('left', data.clientX + 10 + 'px');
    };

    const onMouseOver = (data: React.MouseEvent<HTMLElement>) => {
      const id = `#${data.currentTarget.id}`;
      const point = d3.select(id)?.datum() as d3.PieArcDatum<PieChartDataPoint>;

      tooltip.style('opacity', 1).html(`<b>${point.data.label}</b> - ${point.data.value}`);
    };

    const onMouseLeave = () => tooltip.style('opacity', 0).html('');

    // Draw pie chart segments
    g.selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => color(d.data.label))
      .attr('stroke', 'white')
      .attr('stroke-width', '2px')
      .attr('id', (d) => `${id}-${d.data.label}`.replaceAll(/[^a-z]+/gi, '-'))
      .on('mouseover', onMouseOver)
      .on('mousemove', onMouseMove)
      .on('mouseout', onMouseLeave);

    const legend = svg.append('g').attr('transform', `translate(${width - radius},20)`);
    const labelDimension = 14;

    const ellipsize = (value: string) => `${value.substring(0, 20)}...`;
    legend
      .selectAll(null)
      .data(pie(data))
      .enter()
      .append('rect')
      .attr('y', (d) => labelDimension * d.index * 1.8)
      .attr('width', labelDimension)
      .attr('height', labelDimension)
      .attr('fill', (d) => color(d.data.label))
      .attr('stroke', 'grey')
      .style('stroke-width', '1px');

    legend
      .selectAll(null)
      .data(pie(data))
      .enter()
      .append('text')
      .text((d) => (d.data.label.length > 20 ? ellipsize(d.data.label) : d.data.label))
      .attr('x', labelDimension * 1.2)
      .attr('y', (d) => labelDimension * d.index * 1.8 + labelDimension)
      .style('font-family', 'sans-serif')
      .style('font-size', `${labelDimension}px`);
  }, [data]);

  return <svg ref={svgRef}></svg>;
};

export default PieChart;
