// utils/exportToPNG.ts
export const exportSvgToPng = (svgElement: SVGSVGElement, filename: string = 'chart.png') => {
  // Create an SVG data string
  const svgData = new XMLSerializer().serializeToString(svgElement);

  // Create a Blob from the SVG data
  const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

  // Create an object URL for the Blob
  const url = URL.createObjectURL(svgBlob);

  // Create a new image element
  const img = new Image();
  img.onload = () => {
    // Create a canvas to draw the image
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return;

    // Set canvas size based on SVG size
    canvas.width = svgElement.clientWidth;
    canvas.height = svgElement.clientHeight;

    // Draw the image on the canvas
    context.drawImage(img, 0, 0);

    // Convert canvas to PNG data URL
    const imgURI = canvas.toDataURL('image/png');

    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = imgURI;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the object URL
    URL.revokeObjectURL(url);
  };

  img.src = url;
};
