import { Lesson } from 'types';

export const parseSearch = (
  results: Lesson[],
  key: keyof Lesson,
  names: {
    [k: string]: string;
  }
) => {
  if (!results || !results.length) return [];
  const lessonCount: Record<string, number> = {};

  results.forEach((lesson: Lesson) => {
    const values: string[] = lesson[key];
    values.forEach((value) => {
      if (value in lessonCount) lessonCount[value] += 1;
      else lessonCount[value] = 1;
    });
  });

  return Object.entries(lessonCount).map(([id, value]) => {
    const label = names[id] ?? 'Unknown';
    return { label, value };
  });
};
