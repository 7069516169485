import { IRequest } from 'coral-utility';
import { buckets } from 'types';

const transform = (searchString: string) => {
  if (searchString === '') {
    return `${process.env.REACT_APP_API_URI}/api/lesson/search`;
  } else {
    return `${process.env.REACT_APP_API_URI}/api/lesson/search/${searchString}`;
  }
};

export const search = {
  getSearch: (searchString: string | undefined, filters?: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/search`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        filters,
        query: searchString?.length === 0 ? undefined : searchString,
      }),
    },
  }),
  getFilters: (filters?: any) => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/buckets`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        fields: buckets,
        filters,
      }),
    },
  }),
};
