import { notification } from 'antd';
import { genFetch, requestHandler, RequestType, withToken } from 'coral-utility';
import { analytics } from 'lib/api/analytics';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { apiResponse, TfIdf } from 'types';

export default function useAnalytics() {
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [tfidf, setTfidf] = useState<TfIdf>();

  const { token } = useContext<IAuthContext>(AuthContext);

  const getAnalytics = async () => {
    setAnalyticsLoading(true);
    try {
      const request = {
        onFetch: () => genFetch(analytics.getTfidf())(withToken(token))(),
        onSuccess: async (coreJsonResponse: apiResponse<TfIdf>) => {
          setTfidf(coreJsonResponse.data);

          setAnalyticsLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Fetching analytics failed',
            message: 'Failure Fetching Analytics data',
          });
          setAnalyticsLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { getAnalytics, tfidf, analyticsLoading, setAnalyticsLoading };
}
