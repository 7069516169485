import { IRequest } from 'coral-utility';

export const analytics = {
  getTfidf: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/analytics/tfidf/test`,
    options: {
      method: 'POST',
    },
  }),
};
