// import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from 'lib/store/store';
// import { cacheManager } from 'lib/local-storage/CacheManager';
// import { User, Theme } from 'types';
// import oauthActionTypes from 'coral-utility/features/oauth/state/types';
// import { authentication } from 'lib/api/authentication';
// import {
//   genFetch,
//   withToken,
//   requestHandler,
//   RequestType,
//   tokenManager,
//   CoreJsonResponse,
// } from 'coral-utility';
// import { getNavigationItems } from './navigation';

import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useDispatch } from "react-redux";

// interface ConnectState {
//   authenticated: boolean;
//   checkOAuth: boolean;
//   user?: User;
//   theme: Theme;
// }

// const initialState: ConnectState = {
//   authenticated: false,
//   checkOAuth: false,
//   theme: 'light',
// };

// function save(index: string, data: unknown) {
//   const cache = JSON.parse(cacheManager.getRecord());
//   if (typeof cache === 'object') {
//     cache[index] = data;
//     cacheManager.setRecord(JSON.stringify(cache));
//   } else {
//     cacheManager.flushRecord();
//   }
// }

// function rehydrate(): ConnectState {
//   if (cacheManager.hasRecord()) {
//     try {
//       const cachedSettings = JSON.parse(cacheManager.getRecord());
//       if (typeof cachedSettings === 'object') {
//         if (cachedSettings['theme']) {
//           return {
//             ...initialState,
//             theme: cachedSettings['theme'],
//           };
//         }
//       }
//     } catch (error) {
//       return initialState;
//     }
//   }
//   return initialState;
// }



// export const getCurrentUser = createAsyncThunk('connect/getCurrentUser', async (_, thunkAPI) => {
//   try {
//     const request = {
//       onFetch: () => genFetch(authentication.getUserProfile())(withToken(token))(),
//       onSuccess: (coreJsonResponse: CoreJsonResponse<User, []>) => {
//         thunkAPI.dispatch(authenticatedSuccess(coreJsonResponse.data));
//         thunkAPI.dispatch(getNavigationItems());
//       },
//       onFailure: () => {
//         console.warn('failure to grab current user');
//       },
//       onDeserialize: 'json',
//       type: RequestType.ONE,
//     };
//     await requestHandler(request);
//   } catch (error) {
//     console.error(error);
//   }
// });

// export const logOut = createAsyncThunk('connect/logout', async (_, thunkAPI) => {  
//   try {
//     const request = {
//       onFetch: () => genFetch(authentication.logOut({currentUser: null}))(withToken(token))(),
//       onSuccess: () => {
//         thunkAPI.dispatch(logoutSuccess());
//         tokenManager.removeToken();
//         tokenManager.removeRefreshToken();
//         window.location.reload();
//       },
//       // not sure what it means to fail to log out, will just do the same thing
//       onFailure: () => {
//         thunkAPI.dispatch(logoutSuccess());
//         tokenManager.removeToken();
//         tokenManager.removeRefreshToken();
//         window.location.reload();
//       },
//       onDeserialize: 'json',
//       type: RequestType.ONE,
//     };
//     await requestHandler(request);
//   } catch (error) {
//     console.error(error);
//     thunkAPI.dispatch(logoutSuccess());
//     tokenManager.removeToken();
//     tokenManager.removeRefreshToken();
//     window.location.reload();
//   }
// });

// export const connectSlice = createSlice({
//   name: 'connect',
//   initialState: rehydrate(),
//   reducers: {
//     authenticatedSuccess: (state, action: PayloadAction<User>) => {
//       state.user = action.payload;
//       state.authenticated = true;
//       state.checkOAuth = false;
//     },
//     toggleTheme: (state, action: PayloadAction<Theme>) => {
//       state.theme = action.payload === 'light' ? 'dark' : 'light';
//       save('theme', state.theme);
//     },
//     logoutSuccess: (state) => {
//       state.authenticated = false;
//       state.checkOAuth = false;
//       state.user = undefined;
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(oauthActionTypes.CHECK_OAUTH_TOKEN, (state) => {
//       state.checkOAuth = true;
//     });
//   },
// });

// export const { authenticatedSuccess, toggleTheme, logoutSuccess } = connectSlice.actions;

// // export const isAuthenticated = (state: RootState) => state.connect.authenticated;
// // export const isCheckOAuth = (state: RootState) => state.connect.checkOAuth;

// export default connectSlice.reducer;


export const useConnected = () => {
  const [user, setUser] = useState<{
      email: string,
      name: string,
      roles: string[]
      _id: string
  }>();

  const { logIn, logOut, token } = useContext<IAuthContext>(AuthContext);

  const getUser = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/admin/user/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => response.json())
        .then((response) => setUser(response))
  }

  return { user, getUser, logIn, logOut };
}