export const InfoDescriptions = {
  observationPerType: (
    <span>
      After submission, event observations are categorized as <b>best practices</b>, <b>areas for improvement</b>, and{' '}
      <b>general observations</b> that do not fit into either of the first two categories.
      <p>
        <b>Areas for Improvement</b> signal items to be flagged for future event planning and tracking for progress.
      </p>
      <p>
        A <b>Best Practice</b> provide benchmarks and potential solutions for future event planners, as well as indicate
        items on which teams align well to expected performance.
      </p>
    </span>
  ),
  observationPerEvent: 'Observations per event reflect a summary number of submissions of each event that has occured.',
  observationPerCat:
    'Analytic tools, built into this system, predict the likely thematic categories that each observation addresses. This chart identifies the predicted categories for the entire data set of observations.',
  observationPerCatByEvent:
    'Analytic tools, built into this system, predict the likely thematic categories that each observation addresses. This chart identifies the predicted thematic categories addressed by the observations submitted for each event.',
};
