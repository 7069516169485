import { LoadingOutlined } from '@ant-design/icons';
import { Input, Modal, Tooltip } from 'antd';
import useAddCart from 'components/cart/hooks/useAddCart';
import useAddLessonToCart from 'components/cart/hooks/useAddLessonToCart';
import useGetAllCarts from 'components/cart/hooks/useGetAllCarts';
import React, { useState } from 'react';

import { AddToCartButton } from './lessons-list/lessons-list-sc';
import { Option, SearchPageDiv, Select, Spin } from './styles';

interface IProps {
  lessons: string[];
}

export default function AddToCart(props: IProps) {
  const [visible, setVisible] = useState(false);
  const [selectedCart, setSelectedCart] = useState('');
  const [addCartInput, setAddCartInput] = useState('');
  const { addCart, setAdded, addCartLoading } = useAddCart();
  const { allCarts, getAllCarts, allCartsLoading } = useGetAllCarts();
  const { addLessonToCart, setAddedLesson, addLessonToCartLoading } = useAddLessonToCart();

  function triggerModal() {
    setVisible(true);
    getAllCarts();
  }

  async function callback(id: string) {
    const cartIdAndLessons = { cartId: id, lessons: props.lessons };
    console.log({cartIdAndLessons})
    await addLessonToCart(cartIdAndLessons, setAddedLesson);
    setTimeout(() => setVisible(false), 3000);
  }

  async function handleOkay() {
    if (addCartInput !== '') {
      await addCart(addCartInput, setAdded, callback);
    } else if (selectedCart !== '') {
      const cartIdAndLessons = { cartId: selectedCart, lessons: props.lessons };
      await addLessonToCart(cartIdAndLessons, setAddedLesson);
      setTimeout(() => setVisible(false), 3000);
    }
  }

  function handleCancel() {
    setVisible(false);
  }

  function makeCartOptions(cart: any) {
    return (
      <Option value={cart.id} key={cart.id}>
        {cart.name}
      </Option>
    );
  }

  function setCart(value: any) {
    setSelectedCart(value);
  }

  let placeholder = 'Select a Favorites List';
  let spinner = <span />;
  let disabled = false;
  const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  if (addCartLoading) {
    placeholder = '...Updating Favorites List';
    spinner = <Spin indicator={icon} size="small"></Spin>;
    disabled = true;
  }
  try {
    return (
      <SearchPageDiv>
        <AddToCartButton onClick={triggerModal}>Add To Favorites</AddToCartButton>
        <br />
        <Modal
          title="Add Observation to Favorites List"
          visible={visible}
          onOk={() => handleOkay()}
          onCancel={handleCancel}
          okText="Add Observation To Favorites"
        >
          Choose an Existing Favorites List
          <br />
          <Select
            onChange={setCart}
            placeholder={allCartsLoading ? '...Getting Favorites List' : placeholder}
            disabled={disabled}
          >
            {allCarts.map((cart: any) => {
              return makeCartOptions(cart);
            })}
          </Select>
          {allCartsLoading ? <Spin indicator={icon} size="small"></Spin> : spinner}
          <br />
          And/Or
          <br />
          <Input
            placeholder={addLessonToCartLoading ? '...Adding Lesson(s) to Favorites List' : 'New Favorites List Name'}
            onChange={(event) => setAddCartInput(event.target.value)}
          />
          {addLessonToCartLoading ? <Spin indicator={icon} size="small"></Spin> : spinner}
        </Modal>
      </SearchPageDiv>
    );
  } catch (error) {
    console.error(error);
  }
  return (
    <SearchPageDiv>
      <Tooltip title="...Fetching Carts">
        <AddToCartButton>Add To Favorites</AddToCartButton>
      </Tooltip>
    </SearchPageDiv>
  );
}
