import useGetOneLesson from 'components/Browse/hooks/useGetOneLesson';
import useGetTags from 'components/Browse/hooks/useGetTags';
import { useEffect } from 'react';

import { EditForm, Label, LargeSpinner, PageLoader, StyledLink, StyledPageDiv } from './edit-lesson-sc';

const EditLesson = () => {
  const { getTags, allTags, allTagsLoading, getAllTagsFailed } = useGetTags();
  const { oneLesson, oneLessonLoading, getOneLesson, getLessonFailed } = useGetOneLesson();

  useEffect(() => {
    getTags();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    if (id) {
      getOneLesson(id);
    }
  }, []);

  const makeLeftContent = () => {
    return <div></div>;
  };

  const makeCenterContent = () => {
    if (allTagsLoading || oneLessonLoading) {
      const spinner = <LargeSpinner spin />;
      return (
        <div>
          <PageLoader indicator={spinner} size="large" tip="Loading Observation..." />;
        </div>
      );
    }

    if (getAllTagsFailed) {
      return <div>Get Tags Failed</div>;
    }

    if (getLessonFailed) {
      return <div>Get Observation Failed</div>;
    }

    return (
      <div className="middleDiv">
        <Label>Edit Observation</Label>
        <EditForm tagLists={allTags} lesson={oneLesson}></EditForm>
      </div>
    );
  };

  const makeRightContent = () => {
    return (
      <div>
        <StyledLink to={`/browse`} style={{ marginTop: '1rem' }}>
          Return to Browse
        </StyledLink>
        {oneLesson.id ? (
          <StyledLink to={`/lesson?id=${oneLesson.id}`} style={{ marginTop: '1rem' }}>
            Return to Observation
          </StyledLink>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <StyledPageDiv>
      <div>{makeLeftContent()}</div>
      <div>{makeCenterContent()}</div>
      <div>{makeRightContent()}</div>
    </StyledPageDiv>
  );
};

export default EditLesson;
