import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { event } from 'lib/api/events';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useGetSingleEvent() {

  const { token } = useContext<IAuthContext>(AuthContext);

  const getSingleEvent = async (eventId: string, setCurrentEvent: any, setLoading: any) => {
    setLoading(true);
    try {
      const request = {
        onFetch: () =>
          genFetch(event.getSingleEvent(eventId))(withToken(token))(
            RelationshipsEnums.INCLUDE_RELATIONS
          ),
        onSuccess: (coreJsonResponse: any) => {
          setCurrentEvent(coreJsonResponse.data);
          setLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get cart failed.',
            message: 'Error Getting Cart',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      setLoading(false);
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { getSingleEvent };
}
