// import { tokenManager } from '../local-storage/token-manager';
import { IRequest, RelationshipsEnums } from './types';

export const genericHeaders = (): HeadersInit => ({
  'Content-Type': 'application/json',
});

export const genFetch = (request: IRequest) => {
  return (headers: HeadersInit = genericHeaders(), options: RequestInit = {}) => {
    return (relations: RelationshipsEnums | string = RelationshipsEnums.NONE): Promise<Response> => {
      return fetch(`${request.route()}${relations}`, {
        ...request.options,
        headers,
        ...options,
      });
    };
  };
};

export const withToken = (token: string, options?: HeadersInit): HeadersInit => ({
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
  ...options,
});
