import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { event } from 'lib/api/events';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useCreateNewEvent() {
  const { token } = useContext<IAuthContext>(AuthContext);


  const createNewEvent = async (eventName: string, setCreated: any) => {
    const testEvent = { name: eventName };
    setCreated(false);
    try {
      const request = {
        onFetch: () =>
          genFetch(event.createNewEvent(testEvent))(withToken(token))(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setCreated(true);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Create New Test Event Failed',
            message: 'Error Creating New Test Event',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { createNewEvent };
}
