import { notification } from 'antd';
import { useContext, useState } from 'react';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useUpdateLesson() {
  const [updateLoading, setUpdateLoading] = useState(false);

  const { token } = useContext<IAuthContext>(AuthContext);

  const updateLesson = async (lessonUpdate: any) => {
    try {
      const request = {
        onFetch: () =>
          genFetch(lessons.updateLesson(lessonUpdate))(withToken(token))(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setUpdateLoading(false);
          notification.success({
             description: 'Lesson Updated',
             duration: 0,
             message: 'Lesson Successfully Updated.',
          });
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Updating Lesson Failed',
            message: 'Error Updating Lesson',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      setUpdateLoading(true);
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { updateLesson, updateLoading };
}
