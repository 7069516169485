import { Skeleton, Tooltip } from 'antd';
import * as React from 'react';
import { BucketLabels, BucketNames, TagList } from 'types';

import {
  Container,
  FeaturesHeader,
  FeaturesHeaderText,
  FilterBox,
  LabelContainer,
  Option,
  SearchButton,
} from './styles';

interface Props {
  buckets: any;
  applyFilters: any;
  clearFilters: any;
  selectedFilters: any;
  tags: TagList[];
}

function Filter(props: Props) {
  const { buckets, selectedFilters, tags } = props;

  //option is array of option
  const handleSelection = (value: any, option: any) => {
    const copy: any = { ...selectedFilters };
    if (value.length === 0 && option.length === 0) {
      return;
    }
    if (copy[option[0].title]) {
      delete copy[option[0].title];
      copy[option[0].title] = { values: value, operator: 'or' };
    } else {
      copy[option[0].title] = { values: value, operator: 'or' };
    }
    props.applyFilters(copy);
  };

  const handleDeselect = (value: any) => {
    const copy: any = { ...selectedFilters };
    Object.keys(copy).forEach((key: any) => {
      const index = copy[key].values.indexOf(value);
      if (index !== -1) {
        copy[key].values.splice(index, 1);
      }
      if (copy[key].values.length === 0) {
        delete copy[key];
      }
    });
    props.applyFilters(copy);
  };

  const makeOptions = (key: string, options: any) => {
    const tagItems = tags.find((tag) => tag.model_source === key);
    if (options.length === 0) {
      return (
        <Option key="None" title="None Found" disabled value="">
          None Found
        </Option>
      );
    }
    return options.map((option: any) => {
      const value = tagItems?.listItems?.find((tag) => tag.tag.name === option.name)?.tag.id ?? option.name;
      return (
        <Option title={key} key={value} value={value}>
          <Tooltip title={option.name}>{`${option.name} (${option.doc_count})`}</Tooltip>
        </Option>
      );
    });
  };

  const buildFilter = (key: string, filterItems: any) => {
    if (selectedFilters.hasOwnProperty(key)) {
      return (
        <FilterBox
          key={key}
          onChange={handleSelection}
          onDeselect={handleDeselect}
          value={selectedFilters[key].values}
          mode="multiple"
        >
          {makeOptions(key, filterItems[key])}
        </FilterBox>
      );
    }
    return (
      <FilterBox onChange={handleSelection} placeholder="--- Select ---" mode="multiple">
        {makeOptions(key, filterItems[key])}
      </FilterBox>
    );
  };

  const renderFilters = () => {
    return Object.keys(buckets[0]).map((key: string) => {
      //https://stackoverflow.com/questions/4149276/how-to-convert-camelcase-to-camel-case
      // let label = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());
      return (
        <div key={key}>
          <LabelContainer>
            <div>{BucketLabels[key as BucketNames]}</div>
          </LabelContainer>
          {buildFilter(key, buckets[0])}
        </div>
      );
    });
  };

  return (
    <Container>
      <SearchButton onClick={() => props.applyFilters(selectedFilters)}>Search</SearchButton>
      <SearchButton onClick={() => props.clearFilters()}>Clear Search</SearchButton>
      <FeaturesHeader>
        <FeaturesHeaderText>Filters</FeaturesHeaderText>
      </FeaturesHeader>
      {Object.keys(buckets).length > 0 ? renderFilters() : <Skeleton />}
    </Container>
  );
}

export default Filter;
