interface IRequest {
  route: Function;
  options: RequestInit;
}

export const carts = {
  getCarts: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/`,
    options: {
      method: 'GET',
    },
  }),
  getCart: (cartId: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/${cartId}`,
    options: {
      method: 'GET',
    },
  }),
  removeFilesFromCart: (cartAndLessons: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/${cartAndLessons.cart.id}/delete`,
    options: {
      method: 'DELETE',
      body: JSON.stringify(cartAndLessons),
    },
  }),
  deleteCart: (cartId: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/${cartId}`,
    options: {
      method: 'DELETE',
    },
  }),
  downloadFromCart: (lessonId: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/${lessonId}/download/pdf`,
    options: {
      method: 'get',
    },
  }),
};
