import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Table, Popconfirm } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { NavigationButton } from './NavigationButton';
import { genFetch, withToken, RelationshipsEnums } from '../../../../../utils';
import { navigation } from '../../../state/api/navigation';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

interface Props {
  adminServiceURL: string;
  columnDefinitions?: [];
}

const columnDefinitions = [
  {
    title: 'UUID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'LABEL',
    key: 'label',
    dataIndex: 'label',
    sorter: (a: any, b: any) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0),
  },
  {
    title: 'URI',
    key: 'uri',
    dataIndex: 'uri',
    sorter: (a: any, b: any) => (a.uri > b.uri ? 1 : b.uri > a.uri ? -1 : 0),
  },
  {
    title: 'VISIBLE',
    key: 'isVisible',
    render: (record: any) => <span>{record.isVisible ? <CheckOutlined /> : null}</span>,
  },
  {
    title: 'NEW WINDOW',
    key: 'newWindow',
    render: (record: any) => <span>{record.newWindow ? <CheckOutlined /> : null}</span>,
  },
  {
    title: 'SORT ORDER',
    key: 'sortOrder',
    dataIndex: 'sortOrder',
    sorter: (a: any, b: any) =>
      a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0,
  },
  {
    title: 'PARENT',
    key: 'parentNavigation',
    dataIndex: 'parentNavigation',
  },
  {
    title: 'PARENT ID',
    key: 'parentNavigationId',
    dataIndex: 'parentNavigationId',
  },
];

export const NavigationTable: React.FC<Props> = props => {
  const { token } = useContext<IAuthContext>(AuthContext);
  const { adminServiceURL } = props;
  const [navigationData, setNavigationData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getNavigation = useCallback(() => {
    setLoading(true);
    genFetch(navigation.getNavigation(adminServiceURL))(withToken(token))(
      RelationshipsEnums.NONE
    ).then((initialResponse: any) => {
      if (initialResponse.status === 200) {
        initialResponse.json().then((body: any) => {
          setNavigationData(body.data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [adminServiceURL]);

  useEffect(() => {
    getNavigation();
  }, [getNavigation]);

  const actionDefinitions = [
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (record: any) => (
        <>
          <span style={{ padding: '0 1rem' }} key={record.id}>
            <NavigationButton
              edit
              navigation={JSON.parse(JSON.stringify(record))}
              onSubmit={handleNavigationItemUpdate}
            />
          </span>
          <Popconfirm
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Are you sure you want to delete this navigation item?</span>
              </div>
            }
            onConfirm={() => handleNavigationItemDeletion(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];


  const handleNavigationItemUpdate = (navigationItemId: string, navigationItem: object) => {
    genFetch(
      navigation.editNavigationItem(adminServiceURL, navigationItemId, navigationItem)
    )(withToken(token))().then(getNavigation);
  };

  const handleNavigationItemCreation = (navigationItem: object) => {
    genFetch(navigation.newNavigationItem(adminServiceURL, navigationItem))(
      withToken(token)
    )().then(getNavigation);
  };

  const handleNavigationItemDeletion = (navigationItemId: string) => {
    genFetch(navigation.deleteNavigationItem(adminServiceURL, navigationItemId))(
      withToken(token)
    )().then(getNavigation);
  };

  return (
    <div style={{ width: '100%', padding: '2rem', display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1600px', width: '100%' }}>
        <Table
          dataSource={navigationData}
          columns={[...columnDefinitions, ...actionDefinitions]}
          loading={loading}
          rowKey={navigationData => 'navigation-' + navigationData.id}
        />
        <NavigationButton onSubmit={handleNavigationItemCreation} />
      </div>
    </div>
  );
};
