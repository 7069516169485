import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import useDeleteLesson from 'components/Browse/hooks/useDeleteLesson';
import useGetOneLesson from 'components/Browse/hooks/useGetOneLesson';
import useGetTags from 'components/Browse/hooks/useGetTags';
import ApiImage from 'components/common/ApiImage/api-image';
import Download from 'components/common/Download/download';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { tagFields, TagLabel, tagLabelFields, TagList } from 'types';

import { default as AddToCart } from './addToCart';
import {
  AddCartWrapper,
  Description,
  Label,
  LargeSpinner,
  PageLoader,
  Pill,
  StyledButton,
  StyledLink,
  StyledPageDiv,
  TagContainer,
  Title,
} from './lesson-sc';

const LessonPage = () => {
  const { getTags, allTags } = useGetTags();
  const { getLessonFailed, oneLessonLoading, oneLesson, getOneLesson } = useGetOneLesson();
  const navigate = useNavigate();
  const { deleteLesson } = useDeleteLesson();

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    getTags();
    if (id) {
      getOneLesson(id);
    }
  }, []);

  const generateTagCloud = (lessonData: any) => {
    const tagCloudData: string[] = [];
    if (lessonData) {
      allTags.forEach((tag: TagList) => {
        const lessonTags = lessonData[tag.model_source];
        const matches = tag.listItems.filter((option) => lessonTags.includes(option.tag.id));
        const tagLabels = matches.map((value) => value.tag.name);
        tagCloudData.push(...tagLabels);
      });
      tagCloudData.sort();
    }
    return tagCloudData.map((tag: string) => {
      return <Pill>{tag}</Pill>;
    });
  };

  const makeLeftContent = () => {
    return (
      <>
        <Title>Tags</Title>
        <TagContainer>{generateTagCloud(oneLesson)}</TagContainer>
      </>
    );
  };

  const makeCenterContent = () => {
    const lessonData = oneLesson;

    if (oneLessonLoading) {
      const spinner = <LargeSpinner spin />;
      return (
        <div>
          <PageLoader indicator={spinner} size="large" tip="Loading Lesson..." />
        </div>
      );
    }
    if (getLessonFailed) {
      return <div className="middleDiv">Lesson failed to load, it may not exist in the database.</div>;
    }
    return (
      <div className="middleDiv">
        <Label>
          <div>{lessonData.title}</div>
        </Label>
        <br />
        <Description>
          <h3>Event Year</h3>
          {lessonData.event_year}
        </Description>
        <Description>
          <h3>Description</h3>
          {lessonData.description}
        </Description>
        <Description>
          <h3>Event Description</h3>
          {lessonData.event_description}
        </Description>
        <br />
        <Description>
          <h3>Root Cause</h3>
          {lessonData.root_cause}
        </Description>
        <br />
        <Description>
          <h3>Observation Recommendation</h3>
          {lessonData.recommendations}
        </Description>
        <br />
        <Description>
          <h3>Lesson</h3>
          {lessonData.lesson}
        </Description>
        <br />
        <Description>
          <h3>After Actions</h3>
          {lessonData.after_actions}
        </Description>
        <br />
        <Description>
          <h3>Comments</h3>
          {lessonData.comments}
        </Description>
      </div>
    );
  };

  const handleDeleteLesson = async (id: string) => {
    await deleteLesson(id);
    navigate(`/browse`);
  };

  const makeRightContent = () => {
    const lessonData = oneLesson;
    const sourceFiles = lessonData.file_breadcrumbs ?? [];

    if (getLessonFailed) {
      return (
        <div>
          Error getting Lesson
          <StyledLink to={`/browse`} style={{ marginTop: '1rem' }}>
            Return to Browse
          </StyledLink>
        </div>
      );
    }
    return (
      <div>
        <StyledLink to={`/browse`} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Browse
        </StyledLink>
        <StyledLink to={`/edit?id=${lessonData.id}`}>
          <EditOutlined />
          Edit
        </StyledLink>

        <StyledButton
          onClick={() => (window.confirm('Are you sure?') ? handleDeleteLesson(lessonData.id) : {})}
          style={{ marginTop: '1rem' }}
        >
          <DeleteOutlined />
          Delete
        </StyledButton>

        <AddCartWrapper>
          <AddToCart lessons={[lessonData.id]}></AddToCart>
        </AddCartWrapper>

        {sourceFiles.map((file) => {
          if (file)
            return (
              <div>
                <Download title={file.filename} id={file.id} fileName={file.filename} />
              </div>
            );
        })}
        {lessonData.files.map((file) => {
          return (
            <div>
              <Download title={file.filename} id={file.id} fileName={file.filename} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <StyledPageDiv>
      <div className="leftContent">{makeLeftContent()}</div>
      <div className="centerContent">{makeCenterContent()}</div>
      <div className="rightContent">{makeRightContent()}</div>
    </StyledPageDiv>
  );
};

export default LessonPage;
