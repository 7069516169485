import React, { useState, useEffect, useCallback, useContext } from 'react';
import { genFetch, withToken, RelationshipsEnums } from '../../../../../utils';
import { roles } from '../../../state/api/roles';
import { Table, Tag, Popconfirm } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { roleToColor } from '../../util/roleToColor';
import { RolesButton } from './RolesButton';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

interface Props {
  adminServiceURL: string;
  columnDefinitions?: [];
}

const columnDefinitions = [
  {
    title: 'UUID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'NAME',
    key: 'name',
    dataIndex: 'name',
    sorter: (a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
    render: (role: string) => (
      <span>
        <Tag color={roleToColor(role)} key={role}>
          {role}
        </Tag>
      </span>
    ),
  },
  {
    title: 'DEFAULT',
    key: 'isDefault',
    dataIndex: 'isDefault',
    render: (text: string) => <span>{text ? <CheckOutlined /> : null}</span>,
  },
  {
    title: 'ADMIN',
    key: 'isAdmin',
    dataIndex: 'isAdmin',
    render: (text: string) => <span>{text ? <CheckOutlined /> : null}</span>,
  },
  {
    title: 'EXCLUSIVE',
    key: 'isExclusive',
    dataIndex: 'isExclusive',
    render: (text: string) => <span>{text ? <CheckOutlined /> : null}</span>,
  },
  {
    title: 'DESCRIPTION',
    key: 'description',
    dataIndex: 'description',
  },
  {
    title: 'LAST UPDATED',
    key: 'createdStamp',
    dataIndex: 'createdStamp',
  },
];

export const RolesTable: React.FC<Props> = props => {
  const { adminServiceURL } = props;
  const [rolesData, setRolesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext<IAuthContext>(AuthContext);


  const getRoles = useCallback(() => {
    setLoading(true);
    genFetch(roles.getRoles(adminServiceURL))(withToken(token))(RelationshipsEnums.NONE).then(
      (initialResponse: any) => {
        if (initialResponse.status === 200) {
          initialResponse.json().then((body: any) => {
            setRolesData(body.data);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      }
    );
  }, [adminServiceURL]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const actionDefinitions = [
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (record: any) => (
        <>
          <span style={{ padding: '0 1rem' }} key={record.id}>
            <RolesButton
              edit
              roleData={JSON.parse(JSON.stringify(record))}
              onSubmit={handleRoleUpdate}
            />
          </span>
          <Popconfirm
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Are you sure you want to delete this role?</span>
              </div>
            }
            onConfirm={() => handleRoleDeletion(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleRoleUpdate = (roleId: any, role: object) => {
    genFetch(roles.editRole(adminServiceURL, roleId, role))(withToken(token))().then(getRoles);
  };

  const handleRoleCreation = (role: object) => {
    genFetch(roles.newRole(adminServiceURL, role))(withToken(token))().then(getRoles);
  };

  const handleRoleDeletion = (roleId: string) => {
    genFetch(roles.deleteRole(adminServiceURL, roleId))(withToken(token))().then(getRoles);
  };

  return (
    <div style={{ width: '100%', padding: '2rem', display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1600px', width: '100%' }}>
        <Table
          dataSource={rolesData}
          columns={[...columnDefinitions, ...actionDefinitions]}
          loading={loading}
          rowKey={rolesData => 'roles-' + rolesData.id}
        />
        <RolesButton onSubmit={handleRoleCreation} />
      </div>
    </div>
  );
};
