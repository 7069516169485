import { useContext, useState } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useAddLessonToCart() {
    const [addLessonToCartLoading, setAddLessonToCartLoading] = useState(false);
    const [addedLesson, setAddedLesson] = useState(false);
    const { token } = useContext<IAuthContext>(AuthContext);


    const addLessonToCart = async (cartIdAndLessons: any, setAddLesson: any) => {
        setAddLessonToCartLoading(true);
        setAddedLesson(false);
        try {
            const request = {
                onFetch: () => genFetch(lessons.addLessonToCart(cartIdAndLessons))(withToken(token))(),
                onSuccess: () => {
                    notification.success({
                        description: `Lessons have been added to your cart.`,
                        duration: 3,
                        message: 'Success',
                    });
                    setAddLessonToCartLoading(false);
                    setAddLesson(true);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Add cart failed.',
                        message: 'Failure Adding Cart',
                    });
                    setAddLessonToCartLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    return { addLessonToCart, addLessonToCartLoading, addedLesson, setAddedLesson };
}