import { notification } from 'antd';
import { genFetch, RelationshipsEnums, requestHandler, RequestType, withToken } from 'coral-utility';
import { search } from 'lib/api/search';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useGetSearch() {
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const { token } = useContext<IAuthContext>(AuthContext);

  const getSearchResults = async (searchString: string | undefined, filter: any) => {
    setSearchLoading(true);
    try {
      const request = {
        onFetch: () => genFetch(search.getSearch(searchString, filter))(withToken(token))(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setSearchResults(coreJsonResponse.data);
          setSearchLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get all lessons failed.',
            message: 'Error Getting Lessons',
          });
          setSearchLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  const clearSearchResults = () => {
    setSearchResults([]);
  };

  return { searchResults, searchLoading, getSearchResults, clearSearchResults };
}
