import { UploadOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import type { UploadFile as UploadFileType } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import useDownloadFile from 'components/Browse/hooks/useDownloadFile';
import { tokenManager } from 'coral-utility';
import * as React from 'react';
import { Lesson } from 'types';

import { Upload, UploadButton } from './edit-lesson-sc';

interface Props {
  lesson: Lesson;
  fileList: UploadFileType[];
}

export const UploadPDF: React.FC<Props> = (props) => {
  const { lesson, fileList } = props;
  const { downloadFile } = useDownloadFile();

  const fileChange = (info: any) => {
    const { file } = info;
    if (file.status === 'error') {
      notification.error({ message: 'Error uploading pdf' });
    } else if (file.status === 'success') {
      notification.success({ message: 'Uploaded PDF successfully' });
    }
  };

  const downloadFunction = (file: UploadFile) => {
    downloadFile(file.uid, file.name);
  };

  const bearerToken = tokenManager.getToken();

  const uploadProps = {
    name: 'uploaded_file',
    action: `${process.env.REACT_APP_API_URI}/api/lesson/${lesson.id}/upload/file`,
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
    onChange: fileChange,
    onPreview: downloadFunction,
    defaultFileList: fileList,
    multiple: true,
  };

  return (
    <Upload {...uploadProps}>
      <UploadButton>
        <UploadOutlined />
        Click to Upload
      </UploadButton>
    </Upload>
  );
};
