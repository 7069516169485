export enum BucketNames {
  ObservationType = 'observation_types',
  Event = 'events',
  EventType = 'event_types',
  EventYear = 'event_year',
  Assets = 'assets',
  PartnerAgencies = 'partner_agencies',
}

export const buckets: BucketNames[] = Object.values(BucketNames);
export const BucketLabels: Record<BucketNames, string> = {
  [BucketNames.Event]: 'Event',
  [BucketNames.EventType]: 'Event Type',
  [BucketNames.EventYear]: 'Event Year',
  [BucketNames.ObservationType]: 'Observation Types',
  [BucketNames.Assets]: 'Assets',
  [BucketNames.PartnerAgencies]: 'Partner Agencies',
};

export const tagFields: string[] = [
  'event',
  'eventType',
  'asset',
  'observationType',
  'missionCapabilitiesTaskList',
  'significanceLevel',
  'tools',
  'sourceType',
];

export interface TagLabel {
  name: string;
  label: string;
}

export const tagLabelFields: TagLabel[] = [
  { name: 'event', label: 'Event' },
  { name: 'eventType', label: 'Event Type' },
  { name: 'observationType', label: 'Observation Type' },
  { name: 'missionCapabilitiesTaskList', label: 'Mission Capabilities Task List' },
  { name: 'significanceLevel', label: 'Significance Level' },
  { name: 'tools', label: 'Tools' },
  { name: 'sourceType', label: 'Source Type' },
];
