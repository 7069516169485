import { DatePicker, Form, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import useUpdateLesson from 'components/events/hooks/useUpdateLesson';
import dayjs from 'dayjs';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { TagLabel, TagList } from 'types';
import { Lesson } from 'types';
import { tagFields, tagLabelFields } from 'types';

import { FormLabel, InputBox, SubmitButton } from './edit-lesson-sc';
import { UploadFinalPDF } from './uploadFinalPDF';
// import { default as moment } from 'moment';
// import CKEditor from 'ckeditor4-react';
import { UploadPDF } from './uploadPDF';
import { UploadPicture } from './uploadPicture';

const { useForm } = Form;

interface Props {
  tagLists: TagList[];
  lesson: Lesson;
}

const dropdownWidth = '25rem';
// const stdWidth = '35rem';

const EditLesson: React.FC<Props> = (props) => {
  const { lesson, tagLists } = props;
  const { updateLesson } = useUpdateLesson();
  const [form] = useForm();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    // Assign new values to the lesson object before sending to API
    lesson.title = values.title;
    lesson.event_description = values.event_description;
    lesson.description = values.description;
    //editor may not be set if editor was not touched
    /*lesson.description = values.description.editor
      ? values.description.editor.getData()
      : values.description;*/
    lesson.event_year = values.fiscalYear;
    lesson.lesson = values.lesson;
    lesson.root_cause = values.root_cause;
    lesson.recommendations = values.recommendations;
    lesson.after_actions = values.after_actions;
    lesson.comments = values.comments;

    tagLists.forEach((tag: TagList) => {
      lesson[tag.model_source] = values[tag.model_source];
    });
    // lesson.tags = [];

    // tagFields.forEach((tagField: string) => {
    //   if (values[tagField] && values[tagField].length) {
    //     let tags: string[] = values[tagField];
    //     lesson.tags = [...lesson.tags, ...tags];
    //   }
    // });
    // Send new lesson to API for edit
    const { files, ...toEdit } = lesson;
    await updateLesson(toEdit);
    navigate(`/lesson?id=${lesson.id}`);
  };

  const renderTagFields = () => {
    return tagLists.map((tag: TagList) => {
      const options = tag.listItems.map((item) => ({ label: item.tag.name, value: item.tag.id }));
      const initialValues = lesson[tag.model_source];

      return (
        <Form.Item label={<FormLabel>{tag.name}</FormLabel>} name={tag.model_source} initialValue={initialValues}>
          <Select mode="multiple" allowClear options={options} id={tag.model_source} key={tag.model_source} />
        </Form.Item>
      );
    });
  };

  return (
    <Form onFinish={handleSubmit} form={form}>
      <Form.Item
        label={<FormLabel>Title</FormLabel>}
        rules={[{ required: true, message: 'Enter a title' }]}
        name="title"
        initialValue={lesson.title}
      >
        <InputBox style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>Event Year</FormLabel>}
        rules={[{ required: false, message: 'Enter a an event year' }]}
        name="fiscalYear"
        initialValue={lesson.event_year}
      >
        <InputBox id="fiscalYearBox" style={{ width: '90%' }} rows={1} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>Description</FormLabel>}
        rules={[{ required: false, message: 'Enter observation description' }]}
        name="description"
        initialValue={lesson.description}
      >
        <InputBox id="descriptionBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>Root Cause</FormLabel>}
        rules={[{ required: true, message: 'Enter root cause' }]}
        name="root_cause"
        initialValue={lesson.root_cause}
      >
        <InputBox id="rootCauseBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>Observation Recommendation</FormLabel>}
        rules={[{ required: false, message: 'Enter a recommendation' }]}
        name="recommendations"
        initialValue={lesson.recommendations}
      >
        <InputBox id="recommendationBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>Lesson</FormLabel>}
        rules={[{ required: false, message: 'Enter a lesson' }]}
        name="lesson"
        initialValue={lesson.lesson}
      >
        <InputBox id="lessonBox" style={{ width: dropdownWidth }} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>After Actions</FormLabel>}
        rules={[{ required: false, message: 'Enter after actions' }]}
        name="after_actions"
        initialValue={lesson.after_actions}
      >
        <InputBox id="afterActionsBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item
        label={<FormLabel>Comments</FormLabel>}
        rules={[{ required: false, message: 'Enter comments' }]}
        name="comments"
        initialValue={lesson.comments}
      >
        <InputBox id="commentsBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      {renderTagFields()}
      {}
      <Form.Item label={<FormLabel>Files</FormLabel>}>
        <UploadPDF
          lesson={lesson}
          fileList={lesson.files.map((file) => ({ name: file.filename, uid: file.id, status: 'done' }))}
        />
      </Form.Item>
      {/* <Form.Item label={<FormLabel>Observation Final PDF</FormLabel>}>
        <UploadFinalPDF
          lesson={lesson}
          fileList={lesson.pdf ? [{ name: lesson.pdfOriginal, status: 'done', uid: lesson.pdf }] : []}
        />
      </Form.Item>
      <Form.Item label={<FormLabel>Observation Photo</FormLabel>}>
        <UploadPicture
          lesson={lesson}
          fileList={
            lesson.picture ? [{ name: lesson.picture?.originalFileName, status: 'done', uid: lesson.picture?.id }] : []
          }
        />
      </Form.Item> */}
      <Form.Item
        label={<FormLabel>Event Description</FormLabel>}
        rules={[{ required: false, message: 'Enter event description' }]}
        name="event_description"
        initialValue={lesson.event_description}
      >
        <InputBox id="descriptionBox" rows={5} style={{ width: '90%' }} />
      </Form.Item>
      <Form.Item>
        <SubmitButton htmlType="submit">Save Observation</SubmitButton>
      </Form.Item>
    </Form>
  );
};

export default EditLesson;
