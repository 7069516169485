import { Button, List, Select } from 'antd';
import styled from 'styled-components';

export const FilterBox = styled(Select)`
  font-size: 1.5rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  box-shadow: none;
  background-color: white;
  width: 100%;
  padding: 0.5rem 0;
  * {
    font-size: 1rem;
  }
`;

export const LabelContainer = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: rgb(70, 71, 73);
  font-family: Raleway, sans-serif;

  div {
    font-size: 1.2rem;
    text-align: left;
  }
`;

export const ClearFilterButton = styled(Button)`
  background-color: #e4e4ec;
  border-color: #e4e4ec;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem !important;
  color: black;
  margin-top: 3rem;
  width: 100%;
`;

export const Container = styled.div`
  button:hover,
  button:focus {
    background-color: #9b9b9e;
    color: white;
    border-color: #9b9b9e;
  }
  .ant-select-selection:hover {
    border-color: #9b9b9e;
  }
`;

export const FilterList = styled(List)`
  font-size: 1.2rem;
  width: 27rem;
  .ant-list-header {
    bottom-border: none;
  }
`;

export const Item = styled(List.Item)`
  /* margin-left: 6px; */
  font-size: 1rem;
`;

export const Option = styled(Select.Option)``;

export const AppliedFilterItem = styled.span`
  /* margin-left: 1rem; */
  font-size: 1rem;
`;

export const SearchButton = styled(Button)`
  background-color: #e4e4ec;
  border-color: #e4e4ec;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  margin-top: 1rem;
`;

export const FeaturesHeader = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: rgb(70, 71, 73);
  margin: 0.5rem;
`;

export const FeaturesHeaderText = styled.div`
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  border-bottom: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  text-align: left;
`;
