import { useContext, useState } from 'react';
import { notification } from 'antd';
import { event } from 'lib/api/events';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

export default function useDeleteEvent() {
  const [deleteEventLoading, setDeleteEventLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { token } = useContext<IAuthContext>(AuthContext);


  const deleteEvent = async (
    selectedEvent: any,
    setDelete: any,
    setCurrentEventId: any,
    setCurrentEvent: any
  ) => {
    setDeleteEventLoading(true);
    setDelete(false);
    try {
      const request = {
        onFetch: () =>
          genFetch(event.deleteEvent(selectedEvent.id))(withToken(token))(RelationshipsEnums.NONE),
        onSuccess: () => {
          setDeleteEventLoading(false);
          setDelete(true);
          setCurrentEventId('');
          setCurrentEvent({});
        },
        onFailure: (message: string) => {
          notification.error({
            description: message ?? 'Delete event failed.',
            message: 'Error Deleting Event',
          });
          setDeleteEventLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (err) {
      console.log(err);
    }
  };
  return { deleteEvent, deleteEventLoading, deleted, setDeleted };
}
